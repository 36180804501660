import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const pageImage = () => {
    let image = ''
    switch (params.serviceDetailsId) {
      case '360-event-management':
        image = 'service_1.jpg'
        break;
      case 'show-production':
        image = 'service_2.jpg'
        break;
      case 'sound-system-&-engineering':
        image = 'service_3.jpg'
        break;
      case 'staging':
        image = 'service_4.jpg'
        break;
      default:
        break;
    }
    return image
  }

  const pageDescription = () => {
    let description = ''
    switch (params.serviceDetailsId) {
      case '360-event-management':
        description = 'Below our most 360 Event Management services'
        break;
      case 'show-production':
        description = 'Below our most Show Production'
        break;
      case 'sound-system-&-engineering':
        description = 'Below our most Sound Solutions'
        break;
      case 'staging':
        description = 'Below our most Show Production'
        break;
      default:
        break;
    }
    return description
  }

  return (
    <>
      <PageHeading
        // title='Service Details'
        title={params.serviceDetailsId.replace(/-/g, ' ').toUpperCase()}
        bgSrc='/images/service_hero_bg.jpeg'
        // pageLinkText={params.serviceDetailsId}
        pageLinkText={'SERVICE DETAILS'}
      />
      {/* <Spacing lg='145' md='80'/> */}
      {/* <Div className="container">
        <SectionHeading 
          title='Design working process' 
          subtitle='UI/UX Design' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Sketching'
              subtitle='Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium also a doloremque laudantium, totam remain beatae vitae dictaro enim ipsam sunt explicabo.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Wireframing'
              subtitle='Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium also a doloremque laudantium, totam remain beatae vitae dictaro enim ipsam sunt explicabo.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Prototyping'
              subtitle='Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium also a doloremque laudantium, totam remain beatae vitae dictaro enim ipsam sunt explicabo.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='120' md='50' />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src={`/images/${pageImage()}`} alt="Service" className='cs-radius_15 w-100 object-cover' />
            </Div>
            <Spacing lg='0' md='40' />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">{pageDescription(params.serviceDetailsId)}</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
              {pageRelatedServices(params.serviceDetailsId)}
            </Div>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Let’s disscuse make <br />something <i>cool</i> together'
          btnText='Apply For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}


const pageRelatedServices = (type) => {
  switch (type) {
    case '360-event-management':
      return (
        <>
          <Div className="col-lg-6">
            <Button btnLink='#' btnText='Concept Creation' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Guest Management' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Talent Booking' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Accreditation and Access control' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Prints and decoration' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Fencing' variant='cs-type2' />
            <Spacing lg='0' md='10' />
          </Div>
        </>
      );
    case 'show-production':
      return (
        <>
          <Div className="col-lg-6">
            <Button btnLink='#' btnText='Screens and visuals' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Lights & Lasers' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Projection' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Special Effect' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Show management' variant='cs-type2' />
            <Spacing lg='20' md='10' />
          </Div>
        </>
      );
    case 'sound-system-&-engineering':
      return (
        <>
          <Div className="col-lg-6">
            <Button btnLink='#' btnText='Production & Recording' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Sound systems' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Sound engineering' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Sound installations' variant='cs-type2' />
            <Spacing lg='20' md='10' />
          </Div>
        </>
      );
    case 'staging':
      return (
        <>
          <Div className="col-lg-6">
            <Button btnLink='#' btnText='Trussing' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Scaffolding' variant='cs-type2' />
            <Spacing lg='20' md='10' />
            <Button btnLink='#' btnText='Staging' variant='cs-type2' />
            <Spacing lg='20' md='10' />
          </Div>
        </>
      );
    default:
      return (
        <></>
      )
  }
}