import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import "react-toastify/dist/ReactToastify.css";


export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = React.useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSending, setIsSending] = React.useState(false);
  const form = useRef();

  const validateMail = (mail) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(mail);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log('asd a')
    let isEmailValid = validateMail(values.email);

    if (values?.name === "" || values.email === "" || values.message === "") {
      console.log('asd ?????????')
      toast.error("Complete All Fields!");
    } else if (isEmailValid === false) {
      toast.error("Email is not valid!");
    } else {
      setIsSending(true);
      emailjs
        .sendForm(
          "service_7ek3j0d",
          "template_x67refi",
          form.current,
          "x7wmVlT5GH33Fz56x"
        )
        .then(
          (result) => {
            toast.success("The email was sent successfully");
            setIsSending(false);
            setValues({ name: "", email: "", message: "" });
            console.log(result.text);
          },
          (error) => {
            toast.error("Something went wrong!");
            setIsSending(false);
            console.log(error.text);
          }
        );
    }
  };
  return (
    <>
      <ToastContainer autoClose={1500} />
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" ref={form}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  required name="user_name"
                  value={values.name}
                  onChange={(event) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      name: event.target.value,
                    }))
                  } />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  required
                  name="user_email"
                  value={values.email}
                  onChange={(event) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      email: event.target.value,
                    }))
                  } />
                <Spacing lg="20" md="20" />
              </Div>
              {/* <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div> */}
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  required
                  name="message"
                  value={values.message}
                  onChange={(event) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      message: event.target.value,
                    }))
                  }
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1" onClick={sendEmail}
                  disabled={isSending}>
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927764.3550487261!2d46.26206159131756!3d24.724150391445495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh%20Saudi%20Arabia!5e0!3m2!1sen!2sro!4v1687277463842!5m2!1sen!2sro"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}

