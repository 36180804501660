import React from 'react'
import { Link } from 'react-router-dom'

export default function MenuWidget({ menuItems, menuHeading, variant }) {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  return (
    <>
      {menuHeading && <h2 className="cs-widget_title">{menuHeading}</h2>}
      <ul className={`${variant ? `cs-menu_widget ${variant}` : 'cs-menu_widget cs-style1'} cs-mp0`}>
        {menuItems.map((item, index) => (
          <li key={index} onClick={scrollToTop}>
            {item.href ? <Link to={item.href}>{item.title}</Link> : item.title}
          </li>
        ))}
      </ul>
    </>
  )
}
